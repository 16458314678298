import * as React from "react";
import AssetPage from "../../components/asset-page";

const FlarePage = () => (
  <AssetPage
    asset="Songbird"
    ticker="SGB"
    description={[
      "Songbird is the canary network for Flare.",
      "It is an operational blockchain with a defined token supply that allows new features or applications to be tested under production conditions before they are deployed on the main network.",
      "It is a Layer 1 like Ethereum, with added data acquisition functionality. Songbird can therefore offer developers decentralized access to high-integrity data from other chains and the internet. This enables the creation of dapps with new use cases and monetization models. It also allows these dapps to serve multiple chains through a single deployment.",
      "Flare is Ethereum Virtual Machine (EVM) based, meaning any applications written in Solidity to run on any other EVM chain can also be used on Songbird."
    ]}
    features={[
      {
        title: "Wrap your SGB",
        text: "Easily wrap and unwrap SGB directly in the app.",
        url: "https://support.bifrostwallet.com/en/articles/5588951-wrap-and-delegate-songbird",
        linkText: "How to wrap SGB?"
      },
      {
        title: "Delegate voting power",
        text: "Manage FTSO provider delegations natively.",
        url: "https://support.bifrostwallet.com/en/articles/5588951-wrap-and-delegate-songbird",
        linkText: "How do I delegate?"
      },
      {
        title: "Activate autoclaiming",
        text: "Bifrost Claimbot is a seamless solution that automatically handle claims of FTSO rewards.",
        url: "https://support.bifrostwallet.com/en/articles/7609057-enable-automatic-claiming-for-songbird-in-bifrost-wallet",
        linkText: "How to enable automatic claiming?"
      },
    ]}
  />
);

export default FlarePage;
